import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "HTML5 Video in Safari for the FUll HD Web User Interface",
  "path": "/Frequently_Asked_Question/HTML5_Video_Playback_in_Safari_with_Audio/",
  "dateChanged": "2020-07-17",
  "author": "Mike Polinowski",
  "excerpt": "After Updating to the newest webUI I noticed that I no longer have audio in Safari. Both Firefox and Chrome work without any issues.",
  "image": "../../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "Full HD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='FAQs - HTML5 Video in Safari for the FUll HD Web User Interface' dateChanged='2020-07-17' author='Mike Polinowski' tag='INSTAR IP Camera' description='After Updating to the newest webUI I noticed that I no longer have audio in Safari. Both Firefox and Chrome work without any issues.' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/HTML5_Video_Playback_in_Safari_with_Audio/' locationFR='/fr/Frequently_Asked_Question/HTML5_Video_Playback_in_Safari_with_Audio/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "html5-video-in-safari-for-the-full-hd-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#html5-video-in-safari-for-the-full-hd-web-user-interface",
        "aria-label": "html5 video in safari for the full hd web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`HTML5 Video in Safari for the FUll HD Web User Interface`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: After Updating to the newest webUI I noticed that I no longer have audio in Safari. Both Firefox and Chrome work without any issues.`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: The new web user interface update replaced the Flash and QuickTime video plugin, that were used to display your live stream, with an plugin-free HTML5 video. Your Safari Browser does not allow us to play audio without your permission. You can give your permission by stopping the video stream and restarting it.`}</p>
    <p>{`To permanently allow audio to be played automatically click on `}<strong parentName="p">{`Website Settings`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "874px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/466724ab550116d15927ed8b0403245f/30c92/HTML5_Video_in_Safari_with_Audio_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "32.608695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAAsTAAALEwEAmpwYAAABtklEQVQY0wGrAVT+AGloYoCBgn19fWRlZVpbXFhXWE1OUFBRVGhpa21ub2xtbmxtbmxtbnV1d3h4eWxtbmtsbWtsbWtsbWxtbgCHlKV+j6V+jaN1hJpuf5d7i6Lj5OXv7+/z8/P09PTy8vLz8/Tw8fHy8vL19fX4+Pj5+fn4+Pj5+fn5+voAkaG3ip23iZy4f5GrfJCrgZWx8PHy7u/u7Ovr9vb19/f2+vr5+fn3+fn4/f38+/r59vf2+fr59fb18/PyAJ2en5KTk4qLi4SEhIKCgpqZmM7OzkRDQjg0NJGOg6+qnbOvoru2qb66rMbCsqmll3d8eJCYkm92cWZqYwCXnKOeoaaSlpuAg4iFiIymqKvIx8gcFhYRDhFnZFyln46jno2qpJStqJa1rpypoo+MkY2ao6CCi4dmbmkAlJeagYSJf4GHenyBeXt/kpWYycfIHhUXEQ0QUE5InJSAmpN/n5mEpp6JqKCLrqaPh4uMj5Sac3V0SklEAMXHybW6v7q9v7i6ure4uczNzs/NzRoREhMPEjs6OI2FcJeOd3RuX1FTVZuWgYN+bCYoK0JESo6Pi728tDQh+4ENtOJeAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/466724ab550116d15927ed8b0403245f/e4706/HTML5_Video_in_Safari_with_Audio_01.avif 230w", "/en/static/466724ab550116d15927ed8b0403245f/d1af7/HTML5_Video_in_Safari_with_Audio_01.avif 460w", "/en/static/466724ab550116d15927ed8b0403245f/43f51/HTML5_Video_in_Safari_with_Audio_01.avif 874w"],
              "sizes": "(max-width: 874px) 100vw, 874px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/466724ab550116d15927ed8b0403245f/a0b58/HTML5_Video_in_Safari_with_Audio_01.webp 230w", "/en/static/466724ab550116d15927ed8b0403245f/bc10c/HTML5_Video_in_Safari_with_Audio_01.webp 460w", "/en/static/466724ab550116d15927ed8b0403245f/02c22/HTML5_Video_in_Safari_with_Audio_01.webp 874w"],
              "sizes": "(max-width: 874px) 100vw, 874px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/466724ab550116d15927ed8b0403245f/81c8e/HTML5_Video_in_Safari_with_Audio_01.png 230w", "/en/static/466724ab550116d15927ed8b0403245f/08a84/HTML5_Video_in_Safari_with_Audio_01.png 460w", "/en/static/466724ab550116d15927ed8b0403245f/30c92/HTML5_Video_in_Safari_with_Audio_01.png 874w"],
              "sizes": "(max-width: 874px) 100vw, 874px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/466724ab550116d15927ed8b0403245f/30c92/HTML5_Video_in_Safari_with_Audio_01.png",
              "alt": "INSTAR IP Camera HTML5 Video Playback in Safari with Audio",
              "title": "INSTAR IP Camera HTML5 Video Playback in Safari with Audio",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And open the drop-down menu for the `}<strong parentName="p">{`Auto-Play`}</strong>{` settings:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "875px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7196e229a16979880daaa96481767fa2/71c8e/HTML5_Video_in_Safari_with_Audio_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "29.130434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAAsTAAALEwEAmpwYAAABeUlEQVQY0wFuAZH+AEVPUj9GUz5FUTk/SzA3QzU7RzI4RD9FUURKVk1TX01SXktQW0lOWkBGUjtBTjo/TDpATTk/TDI4RiUsOQChopunpqepqaihoaCcm5udnZyfn52npqWurax2dXVxcXB3dnV/fn2FhIN/fn15d3d0c3KEg4K5uLeqqqkA8fDx9/f29fX09PT1/f39/v7+/v7+////9PT0iomIgoKBj5CQmJiXlpeVmZmYjo6OfH18m5ub/////v7+AOvs7enr7Orr7e7u7u3t7fj4+L29vW5tbY2Lh316c3Zzb3x+gImHgYiGf4OCe3FybmhraXF1c4+RjGpoaADn6e3n6u3m6e3w8PHt7e3///+Kh4cAAAAyMTBkYVdpZVlxbWB6dmqHg3mJiIB8fXh2eHZiZ2RpcGhJTk8A6ezu6+zu6evu8PDw7e3t////i4eIAgAAKCcqX1tQcGtcdnFjenVlgHxsiIR2fX57bHByXWFgPUFBS01IE3zIZuDIN58AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7196e229a16979880daaa96481767fa2/e4706/HTML5_Video_in_Safari_with_Audio_02.avif 230w", "/en/static/7196e229a16979880daaa96481767fa2/d1af7/HTML5_Video_in_Safari_with_Audio_02.avif 460w", "/en/static/7196e229a16979880daaa96481767fa2/c963a/HTML5_Video_in_Safari_with_Audio_02.avif 875w"],
              "sizes": "(max-width: 875px) 100vw, 875px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7196e229a16979880daaa96481767fa2/a0b58/HTML5_Video_in_Safari_with_Audio_02.webp 230w", "/en/static/7196e229a16979880daaa96481767fa2/bc10c/HTML5_Video_in_Safari_with_Audio_02.webp 460w", "/en/static/7196e229a16979880daaa96481767fa2/a1ca4/HTML5_Video_in_Safari_with_Audio_02.webp 875w"],
              "sizes": "(max-width: 875px) 100vw, 875px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7196e229a16979880daaa96481767fa2/81c8e/HTML5_Video_in_Safari_with_Audio_02.png 230w", "/en/static/7196e229a16979880daaa96481767fa2/08a84/HTML5_Video_in_Safari_with_Audio_02.png 460w", "/en/static/7196e229a16979880daaa96481767fa2/71c8e/HTML5_Video_in_Safari_with_Audio_02.png 875w"],
              "sizes": "(max-width: 875px) 100vw, 875px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7196e229a16979880daaa96481767fa2/71c8e/HTML5_Video_in_Safari_with_Audio_02.png",
              "alt": "INSTAR IP Camera HTML5 Video Playback in Safari with Audio",
              "title": "INSTAR IP Camera HTML5 Video Playback in Safari with Audio",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And allow media to be played automatically in your cameras webui:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "868px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3eab7e64f0b8f454b85e5f76c72f2edb/748b0/HTML5_Video_in_Safari_with_Audio_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "32.608695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAAsTAAALEwEAmpwYAAABsElEQVQY02Nw8AywcvSzcfY3tnTVNXEwsnTVM3W0dvC1dQ7wDoz19I/28o9294lwdA+xcvA1s/Uyt/Uyt/M2sXLXMbZnmD576vx58xcsmD9t6tQJEyZMnDhh8qRJ06dPW7lieXVVZV5uTnFhQWlJcX19XX19bVlpSWlJcWFhwYQJ/WvWrGb48vnz9+/fv379+v3793///v1HAvPnz2uor+vr6587Z86c2bNBaM7sjo6O+vq6w4cP////n+H16zevXr95/ebN27dv37x9++nTp3Pnzk+aNHHRgvntrQ3lJXnVtQ3tHW1dHc1trU21dTUFRUXZ+Xk9E3rnz5/D8OL1u8fP3zx79fbjx4/v3r37+/fvtMlTGBkYdDQ1UpIisjNi87ISq8syM4rrMxoXZdTPja+c453eE5mUFJ8SDdL87OXbF6/ffvgA0vzv37/pk6dwMbOoKKkE+btnJIflZcVWlaTmZCUWFeVXVpbnFZWExyaGRkW7+3ozvH33/vXb9x8+foTYDNI8aTI7A4OstHxcdNCEzpIJHaUtdXmdzYVzplTXlKc6OnuGhIZlZSW1N5cBAIJI6RJBfntkAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3eab7e64f0b8f454b85e5f76c72f2edb/e4706/HTML5_Video_in_Safari_with_Audio_03.avif 230w", "/en/static/3eab7e64f0b8f454b85e5f76c72f2edb/d1af7/HTML5_Video_in_Safari_with_Audio_03.avif 460w", "/en/static/3eab7e64f0b8f454b85e5f76c72f2edb/67bb0/HTML5_Video_in_Safari_with_Audio_03.avif 868w"],
              "sizes": "(max-width: 868px) 100vw, 868px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3eab7e64f0b8f454b85e5f76c72f2edb/a0b58/HTML5_Video_in_Safari_with_Audio_03.webp 230w", "/en/static/3eab7e64f0b8f454b85e5f76c72f2edb/bc10c/HTML5_Video_in_Safari_with_Audio_03.webp 460w", "/en/static/3eab7e64f0b8f454b85e5f76c72f2edb/1ae05/HTML5_Video_in_Safari_with_Audio_03.webp 868w"],
              "sizes": "(max-width: 868px) 100vw, 868px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3eab7e64f0b8f454b85e5f76c72f2edb/81c8e/HTML5_Video_in_Safari_with_Audio_03.png 230w", "/en/static/3eab7e64f0b8f454b85e5f76c72f2edb/08a84/HTML5_Video_in_Safari_with_Audio_03.png 460w", "/en/static/3eab7e64f0b8f454b85e5f76c72f2edb/748b0/HTML5_Video_in_Safari_with_Audio_03.png 868w"],
              "sizes": "(max-width: 868px) 100vw, 868px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3eab7e64f0b8f454b85e5f76c72f2edb/748b0/HTML5_Video_in_Safari_with_Audio_03.png",
              "alt": "INSTAR IP Camera HTML5 Video Playback in Safari with Audio",
              "title": "INSTAR IP Camera HTML5 Video Playback in Safari with Audio",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      